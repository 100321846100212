@import 'tailwindcss/base';
@import 'styles/base';
@import 'tailwindcss/components';

/* Packages */
@import 'tippy.js/dist/tippy.css';
@import 'swiper/swiper-bundle.css';

/* Custom components start */
@import 'styles/fonts';
@import 'styles/components';
@import 'styles/nav';
@import 'styles/buttons';
@import 'styles/animations';
@import 'styles/dropdown';
@import 'styles/sliders';
@import 'styles/inputs';
@import 'styles/tabs';
@import 'styles/assessments';
@import 'styles/body-text';
@import 'styles/stackable-content';

@import 'tailwindcss/utilities';

/* Compontents */
@import 'styles/components/curriculum-stages';
@import 'styles/components/tippy';
@import 'styles/components/select2';
@import 'styles/components/skills-list';
@import 'styles/components/skill-card';
@import 'styles/components/skill-assessment';
@import 'styles/components/stacked-thumbnails';
@import 'styles/components/digila-form';
@import 'styles/components/checklist';
@import 'styles/components/video-modal';
@import 'styles/components/interest-card';
@import 'styles/components/circular-loader';
@import 'styles/components/percentage-graph';
@import 'styles/components/progress-bar';
@import 'styles/components/compact-container';
@import 'styles/components/modal';
@import 'styles/components/goals-list';
@import 'styles/components/goal-units';
@import 'styles/components/message';
@import 'styles/components/child-card-compact';
@import 'styles/components/setting-card';
@import 'styles/components/on-off-switch';
@import 'styles/components/drag-drop-area';
@import 'styles/components/comments-list';
@import 'styles/components/comment';

/* Microanimations */
@import 'styles/microanimations/alert';
@import 'styles/microanimations/cloudy';
@import 'styles/microanimations/rainbow';
@import 'styles/microanimations/sun';
@import 'styles/microanimations/chat';
