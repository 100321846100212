.interest-card {
  @apply relative flex flex-col justify-end px-16 py-8 overflow-hidden rounded-16 aspect-interests cursor-pointer;

  &:hover {
    /*.interest-card__image {
      scale: 1.1;
    } */
  }

  &__background {
    @apply absolute inset-0 m-auto;
  }

  &__image {
    @apply absolute object-cover w-full h-full basic-animation;
  }

  &__overlay {
    @apply absolute inset-0 m-auto;
    background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0) 0%,
      rgba(10, 10, 10, 0.22) 60%,
      rgba(10, 10, 10, 0.7) 100%
    );
  }

  &__title {
    @apply relative font-bold text-white text-15;
  }

  &__checkbox {
    @apply absolute w-0 h-0 opacity-0;
  }

  &__checked-indicator {
    @apply size-24 absolute top-8 right-8 z-10 hidden;
  }

  &:has(.interest-card__checkbox:checked) {
    .interest-card__checked-indicator {
      @apply block;
    }
  }

  &:has(.interest-card__checkbox:focus-visible) {
    @apply ring-2 ring-black ring-offset-2;
  }
}
