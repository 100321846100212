.main-menu-item {
  @apply block px-24 py-12 mx-4 font-semibold text-17 rounded-12 hover:bg-blue-200 bg-transparent basic-animation;

  &:active,
  &:focus,
  &.active {
    @apply bg-beige-100;
    box-shadow: 0 0 0 1px theme('colors.white') inset;
  }

  &--mobile {
    @apply p-16 bg-transparent rounded-8 title-s;
  }
}

.fixed-nav {
  @apply md:hidden fixed rounded-8 bg-beige-500 p-8 flex items-center justify-between;
  z-index: 1000;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 544px;
}

.fixed-menu {
  @apply opacity-0 pointer-events-none fixed w-full h-full bg-gradient top-0 left-0 basic-animation;
  z-index: 999;

  &.is-open {
    @apply opacity-100 pointer-events-auto;
  }
}

.dropdown-menu-item {
  @apply block p-16 bg-transparent rounded-8 basic-animation hover:bg-blue-200;
  &:active,
  &:focus,
  &.active {
    @apply bg-white;
  }
}

.user-menu-item {
  @apply block p-16 bg-transparent rounded-8 basic-animation hover:bg-blue-200;
  &:active,
  &:focus,
  &.active {
    @apply bg-beige-100;
    box-shadow: 0 0 0 1px theme('colors.white') inset;
  }
}

.digila-dropdown .user-menu-item {
  &:active,
  &:focus,
  &.active {
    @apply bg-white shadow-none;
  }
}
