/* The switch - the box around the on-off-switch__slider */
.on-off-switch {
  @apply relative inline-block bg-beige-400 min-w-[75px] h-[30px] border border-beige-700 rounded-full p-4 basic-animation;

  /* Hide default HTML checkbox */
  input {
    @apply opacity-0 w-0 h-0;

    &:focus-visible + .on-off-switch__slider {
      outline: 2px solid #000;
      outline-offset: 3px;
    }
  }

  /* The on-off-switch__slider */
  &__slider {
    @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 basic-animation rounded-full z-10;

    &::before {
      @apply absolute size-20 left-4 bottom-4 bg-beige-700 basic-animation rounded-full;
      content: '';
    }
  }

  &__text {
    @apply text-black h-full absolute top-0 content-center font-medium text-13 basic-animation pointer-events-none block;

    &--on {
      @apply opacity-0 left-8;
    }
    &--off {
      @apply opacity-100 right-8;
    }
  }

  &:has(:checked) {
    @apply bg-white;
    .on-off-switch__slider::before {
      @apply bg-blue-700;
      left: calc(100% - 24px);
    }
    .on-off-switch__text--on {
      @apply opacity-100;
    }
    .on-off-switch__text--off {
      @apply opacity-0;
    }
  }
}
