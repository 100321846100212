.digila-dropdown {
  @apply hidden flex-col absolute z-10 mt-4 top-[100%] left-0 bg-white rounded-16;
  --dropdown-move: 0px;
  --arrow-width: 12px;
  --border-width: 5px;
  width: 400px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 170px);
  box-shadow: 0px 38.519px 25.481px 0px rgba(14, 18, 24, 0.04),
    0px 20px 13px 0px rgba(14, 18, 24, 0.04),
    0px 8.148px 6.519px 0px rgba(14, 18, 24, 0.03),
    0px 1.852px 3.148px 0px rgba(14, 18, 24, 0.02);
}

.digila-dropdown.digila-dropdown-open {
  @apply flex;
}

/* dropdown positions */
.digila-dropdown-bottom-right {
  @apply left-auto right-0;
}

.digila-dropdown-top-left {
  @apply top-auto bottom-[100%] mt-0 mb-4;
}

.digila-dropdown-top-right {
  @apply top-auto bottom-[100%] left-auto right-0 mt-0 mb-4;
}

/* Bordered dropdown style */
.digila-dropdown-bordered {
  @apply mt-8 mb-8 border-2;
}

@screen xs {
  .digila-dropdown {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 400px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.digila-dropdown-lg {
  width: 400px;
}

@screen xs {
  .digila-dropdown-lg {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 400px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.digila-dropdown.digila-dropdown-sm {
  width: 200px;
}

@screen xs {
  .digila-dropdown-sm {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 200px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.digila-dropdown.digila-dropdown-xs {
  width: 150px;
  min-width: 0;
}

.digila-dropdown.digila-dropdown-xxs {
  width: 64px;
  min-width: 0;
}

@screen xs {
  .digila-dropdown-xs {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 150px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.digila-dropdown-close:hover {
  @apply opacity-80;
}

.icon-rotate {
  @apply basic-animation;
}

[aria-expanded='true'] .icon-rotate {
  @apply transform rotate-180 basic-animation;
}

[aria-expanded='true'][data-toggle='animatetoggle'] .icon-rotate {
  @apply transform rotate-90 basic-animation;
}
