.partners-slider,
.interests-slider,
.people-slider,
.curriculum-slider,
.stories-slider {
  .people-slider-controls,
  .stories-slider-controls,
  .partners-slider-controls,
  .curriculum-slider-controls,
  .interests-slider-controls {
    .swiper-button-disabled {
      @apply opacity-50;
    }

    .people-slider-pagination,
    .stories-slider-pagination,
    .partners-slider-pagination,
    .curriculum-slider-pagination,
    .interests-slider-pagination {
      @apply relative top-auto bottom-auto;

      .swiper-pagination-bullet {
        @apply opacity-100 bg-beige-700 outline-offset-2;
        &:focus-visible {
          @apply outline-[#000] outline-2;
        }

        &.swiper-pagination-bullet-active {
          @apply bg-blue-900;
        }
      }
    }
  }
}

.partners-slider {
  &.swiper-autoheight {
    li {
      @apply xs:h-full;
    }
  }
}

.digila-slider-controls {
  @apply h-40;

  .swiper-button-disabled {
    @apply opacity-50;
  }

  .swiper-pagination.digila-slider-pagination {
    @apply relative top-auto bottom-auto flex;

    .swiper-pagination-bullet {
      @apply opacity-100 bg-beige-700 outline-offset-2;

      &:focus-visible {
        @apply outline-[#000] outline-2;
      }

      &.swiper-pagination-bullet-active {
        @apply bg-blue-900;
      }
    }
  }
}

.video-slider {
  li.swiper-slide {
    @apply h-auto;
  }
}

.curriculum-slider {
  .swiper-wrapper {
    min-height: 254px;
  }
  .swiper-slide-content {
    @apply transition-all duration-500 ease-in-out;
    width: 80px;
  }

  .swiper-slide {
    @apply transition-all duration-500;
    translate: calc(-100% + 393px) 0;
  }

  .swiper-slide-active,
  .swiper-slide-prev {
    @apply block;
    translate: 0;

    .swiper-slide-content {
      @apply w-full;
      transition-duration: 500ms;
    }
  }

  .swiper-slide-next {
    .swiper-slide-content {
      width: 230px;
    }

    + .swiper-slide {
      translate: calc(-200% + 639px) 0;

      + .swiper-slide {
        translate: calc(-300% + 734px) 0;

        .swiper-slide-content {
          @apply w-52;
        }
      }
    }
  }

  &.swiper-initialized {
    .swiper-slide {
      @apply block;
    }
  }
}
