@keyframes alert0_t {
  0% {
    translate: 6px 9px;
  }
  20% {
    translate: 6px 9px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  55% {
    translate: 6px 5.5px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 6px 9px;
  }
}
@keyframes alert1_t {
  0% {
    translate: 0px 0px;
  }
  10% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.2, 1, 1, 1);
  }
  55% {
    translate: 0px -3px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.2, 1, 1, 1);
  }
}
@keyframes alert2_t {
  0% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.2, 1, 1, 1);
  }
  55% {
    translate: 0px -3px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 0px;
  }
}
