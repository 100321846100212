.btn {
  @apply rounded-full px-20 md:px-32 py-16 text-15 md:text-17 font-normal inline-flex items-center justify-center basic-animation whitespace-nowrap text-center relative h-56;
}

.btn--primary {
  @apply bg-black h-40 text-15 text-white hover:text-blue-100 basic-animation px-20 md:px-32 font-semibold;

  .btn-icon {
    @apply absolute basic-animation top-1/2 transform -translate-y-1/2 opacity-0;
    right: 20px;

    @screen md {
      right: 28px;
    }
  }

  &:hover {
    padding-left: 14px;
    padding-right: 26px;

    @screen md {
      padding-left: 22px;
      padding-right: 42px;
    }

    .btn-icon {
      @apply opacity-100;
      right: 8px;

      @screen md {
        right: 14px;
      }
    }
  }

  &-md {
    @apply h-52;
  }
}

.btn--secondary {
  @apply bg-yellow-500 text-blue-800 hover:bg-black hover:text-white px-64 focus:bg-black focus:text-white active:bg-black active:text-white font-semibold;
}

.btn--secondary-md {
  @apply bg-yellow-500 text-blue-800 hover:bg-black hover:text-white px-32 inline-flex justify-center text-15 items-center focus:bg-black focus:text-white active:bg-black active:text-white h-52 py-5 font-semibold;
}

.btn--secondary-sm {
  @apply bg-yellow-500 text-blue-800 hover:bg-black hover:text-white px-32 inline-flex justify-center items-center focus:bg-black focus:text-white active:bg-black active:text-white h-40 py-5;
}

.btn--tertiary {
  @apply border border-black hover:bg-black text-black hover:text-grey h-56 font-semibold outline-offset-2;
}

.btn--tertiary-md {
  @apply border border-black text-black hover:bg-black hover:text-grey h-52 py-5 text-15 font-semibold;
}

.btn--text {
  @apply no-underline text-blue-700 hover:text-blue-900 px-0;
}

.text-link,
a.text-link,
.body-text a.text-link {
  @apply text-blue-700 underline basic-animation hover:text-blue-900 active:text-blue-900 inline-flex items-center font-semibold;

  &--sm {
    @apply text-15 font-bold;
  }
}

.btn-bordered {
  @apply flex justify-center items-center rounded-8 border border-[#B9AEAE] text-black text-14 font-semibold px-24 py-8 basic-animation hover:bg-black hover:text-white hover:border-black;

  &-icon {
    @apply text-black basic-animation;
  }

  &:hover {
    .btn-bordered-icon {
      @apply text-white;
    }
  }

  &--round {
    @apply rounded-full border-black pl-12 pr-16;
  }
}

.btn-authentication {
  @apply bg-white w-full gap-16 focus-visible:outline-offset-2 rounded-full h-52 border-black py-16 justify-center flex items-center border text-left text-black text-15 font-semibold px-24 basic-animation hover:bg-black hover:text-white;
  .btn-authentication__icon {
    @apply w-24 h-24 inline-block shrink-0;
  }

  .btn-authentication__content {
    @apply flex gap-16 items-center justify-start w-full;
    max-width: 240px;
  }

  &--inverted {
    @apply bg-black text-grey hover:text-black hover:bg-white border-black;
  }
}

.btn-assessment {
  @apply flex justify-center w-full px-8 py-8 text-blue-800 bg-white border text-14 border-beige-400 basic-animation rounded-8 hover:bg-blue-200 hover:border-blue-300;

  &.active {
    @apply bg-yellow-300 border-yellow-500;
  }
}

.btn-assessment-trigger {
  @apply flex items-center justify-center py-8 font-semibold bg-white border rounded-8 border-beige-500 px-36 text-15 basic-animation hover:bg-blue-200 hover:border-blue-300 active:bg-beige-100 focus:bg-beige-100 focus:border-beige-500 active:border-beige-500 whitespace-nowrap sm-down:w-full;

  &.active {
    @apply bg-yellow-300 border-yellow-500 px-16;
  }
}

.btn-filter {
  @apply rounded-8 border whitespace-nowrap border-beige-500 inline-flex gap-x-10 text-center justify-center px-12 py-8 text-14 text-black font-semibold basic-animation h-42 items-center from-[#F2EBE3] bg-gradient-to-t hover:bg-black hover:text-white hover:border-black;

  &:hover,
  &.active {
    @apply bg-black text-white border-black bg-gradient-to-t from-black;
  }
}

.btn-playlist {
  @apply flex justify-between w-full px-20 py-16 overflow-hidden rounded-4 bg-beige-400 basic-animation gap-8;

  &-icon {
    @apply flex-shrink-0 w-24 h-24 mr-12 text-blue-800 basic-animation;
  }

  &-duration {
    @apply font-semibold text-blue-800 text-15;
  }

  &:hover {
    @apply bg-beige-500;
  }

  &.active,
  &[aria-expanded='true'] {
    @apply bg-blue-100;

    .btn-playlist-icon svg {
      @apply fill-black;
    }

    .btn-playlist-duration {
      @apply font-bold;
    }
  }
}
