.message {
  @apply border border-beige-700 p-16 rounded-8 bg-beige-100 text-blue-700 @container;

  &__icon {
    @apply size-40 mb-12 p-8 rounded-full border;
  }

  &__content {
    @apply font-medium mb-24 text-14 @[345px]:text-15 @[450px]:text-base;
    a {
      @apply underline;
    }
  }

  &__buttons {
    @apply flex flex-wrap gap-24 items-center;
  }

  &--info {
    .message__icon {
      @apply border-blue-600 text-blue-800;
    }

    .message__content a {
      @apply text-blue-900;
    }
  }

  &--alert {
    @apply border-yellow-500;

    .message__icon {
      @apply text-yellow-500 border-yellow-500;
    }
  }

  &--error {
    @apply border-neg-200;

    .message__icon {
      @apply text-neg-200;
    }

    a {
      @apply text-neg-200;
    }
  }

  &--success {
    @apply border-pos-200;

    .message__icon {
      @apply text-pos-200;
    }
  }

  & > *:last-child {
    @apply mb-0;
  }
}
