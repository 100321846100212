.modal {
  @apply fixed inset-0 w-full h-full z-50 bg-transparent m-auto overflow-hidden basic-animation;
  max-width: 100vw;
  max-height: 100vh;
  translate: 0 0;
  transition-behavior: allow-discrete;

  &::backdrop {
    @apply bg-white/80 basic-animation;
    backdrop-filter: blur(2px);
    transition-behavior: allow-discrete;
  }

  &:modal {
    @apply left-auto h-full w-full max-w-[640px] bg-white;
  }

  &__article {
    @apply hidden basic-animation;
    transition-behavior: allow-discrete;

    &.is-visible {
      @apply flex;
    }
  }

  &__close {
    @apply focus-visible:-outline-offset-2;
  }

  .modal::backdrop {
    @apply opacity-0;
  }
}

@starting-style {
  .modal {
    translate: 100% 0;

    &::backdrop {
      @apply opacity-0;
    }

    &__article {
      &.is-visible {
        @apply flex;
      }
    }
  }
}

.modal:not(:modal) {
  @apply max-w-[640px] right-0 mr-0;
  translate: 100% 0;
  &::backdrop {
    @apply opacity-0;
  }
}

#comments-modal {
  &:modal {
    @apply max-w-[720px];
  }

  &::backdrop {
    @apply bg-[#000]/10;
  }
}
