.skill-assessment {
  .select2-selection {
    @apply border border-beige-700 rounded-4 hover:bg-blue-200 hover:border-blue-300 basic-animation py-4;

    background-repeat: no-repeat;
    background-position: 0.5rem center;
    background-size: 1.5rem 1.5rem;
  }

  .select2.select2-container .select2-selection__arrow {
    top: 4px;
  }

  .select2-container--open {
    .select2-selection {
      @apply bg-beige-100 border-beige-500;
    }
  }

  &:has(> select option:checked:not([value='-1'])) {
    .select2-selection {
      @apply bg-yellow-300 border-yellow-500;
    }
  }

  &:has(> select option:checked[data-icon='icon-rainbow']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-rainbow.svg);
    }
  }

  &:has(> select option:checked[data-icon='icon-cloudy']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-cloudy.svg);
    }
  }

  &:has(> select option:checked[data-icon='icon-sun']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-sun.svg);
    }
  }

  &:has(> select option:checked[data-icon='icon-animated-rainbow']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-animated-rainbow.svg);
    }
  }

  &:has(> select option:checked[data-icon='icon-animated-cloudy']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-animated-cloudy.svg);
    }
  }

  &:has(> select option:checked[data-icon='icon-animated-sun']) {
    .select2-selection {
      @apply pl-40;
      background-image: url(./images/icon-animated-sun.svg);
    }
  }
}

.select2-skill-assessment {
  @apply p-8;

  &.select2-dropdown--below {
    @apply mt-2;
  }

  &.select2-dropdown--above {
    @apply -translate-y-2;
  }
}
