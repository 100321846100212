@keyframes cloudy0_t {
  0% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  58.824% {
    translate: -1.2px 0.5px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 0px;
  }
}
@keyframes cloudy1_t {
  0% {
    translate: -1px 1px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  58.824% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: -1px 1px;
  }
}
@keyframes cloudy2_t {
  0% {
    translate: -3px 0px;
  }
  5.882% {
    translate: -3px 0px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  70.588% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: -3px 0px;
  }
}
@keyframes cloudy3_t {
  0% {
    translate: 0px 3px;
  }
  5.882% {
    translate: 0px 3px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  70.588% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 3px;
  }
}
@keyframes cloudy4_t {
  0% {
    translate: -2px 2px;
  }
  5.882% {
    translate: -2px 2px;
    animation-timing-function: cubic-bezier(0.3, 1, 0.6, 1);
  }
  70.588% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: -2px 2px;
  }
}
