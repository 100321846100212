.checklist {
  @apply flex flex-col gap-16;

  li {
    @apply flex items-center gap-8;

    &::before {
      @apply inline-block size-24 shrink-0;
      content: '';
      background-image: url('/images/icon-check-filled-yellow.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
