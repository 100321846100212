.stackable-content-card-container {
  margin-bottom: 9rem;
}

.stackable-content-card {
  &:nth-child(1) {
    @apply mt-24;
    --nth-child: 1;
  }
  &:nth-child(2) {
    --nth-child: 2;
  }
  &:nth-child(3) {
    --nth-child: 3;
  }
  &:nth-child(4) {
    --nth-child: 4;
  }
  &:nth-child(5) {
    --nth-child: 5;
  }
  &:nth-child(6) {
    --nth-child: 6;
  }
  &:nth-child(7) {
    --nth-child: 7;
  }
  &:nth-child(8) {
    --nth-child: 8;
  }
  &:nth-child(9) {
    --nth-child: 9;
  }
  &:nth-child(10) {
    --nth-child: 10;
  }

  top: calc((var(--nth-child) - 1) * 4.5rem);
  margin-bottom: -9rem;
  margin-top: 10.5rem;
  padding-bottom: 9rem;
}
