.percentage-graph {
  @apply -rotate-90;

  &__circle {
    @apply stroke-transparent;
    stroke-linecap: round;
    stroke-dasharray: 0, 100;

    &--background {
      @apply stroke-white;
      stroke-dasharray: 100, 100;
    }

    &--target {
      stroke-dashoffset: calc(-100 + calc(100 - var(--percentage-main)));
      animation: 500ms ease-out 500ms percentage-target-animation;
      animation-fill-mode: forwards;
    }

    &--main {
      animation: 500ms ease-in percentage-main-animation;
      animation-fill-mode: forwards;
    }
  }
}

.percentage-graph--only-main {
  .percentage-graph__circle--main {
    animation-duration: 1000ms;
    animation-timing-function: ease-in-out;
  }

  .percentage-graph__circle--target {
    animation-delay: 1000ms;
  }
}

@keyframes percentage-target-animation {
  1% {
    @apply stroke-blue-900;
  }
  100% {
    @apply stroke-blue-900;
    stroke-dasharray: var(--percentage-target-dasharray), 100;
    /* stroke-dasharray: 37, 100; */
  }
}

@keyframes percentage-main-animation {
  1% {
    @apply stroke-yellow-500;
  }
  100% {
    @apply stroke-yellow-500;
    stroke-dasharray: var(--percentage-main), 100;
  }
}
