body {
  @apply text-base font-body leading-normal font-normal bg-white text-blue-900;

  &.fixed-nav-open,
  &:has(dialog[open]) {
    @apply overflow-hidden;
    padding-right: var(--scrollbar-width);
  }
}

html {
  scroll-behavior: smooth;
  interpolate-size: allow-keywords;
}

a,
input,
button,
textarea {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #000;
    /* outline-offset: 0; */
  }
}

.outline-offset:focus-visible {
  outline-offset: 3px;
}

.-outline-offset:focus-visible {
  outline-offset: -3px;
}

.title-xl {
  @apply font-heading font-medium text-32 md:text-48 text-blue-900;
}

.title-l {
  @apply font-heading font-medium text-24 sm:text-32 text-blue-900;
}

.title-md {
  @apply font-heading font-medium md-down:leading-snug text-24 md:text-32 text-blue-900;
}

.title-m {
  /* @apply font-heading font-medium text-20 md:text-24 text-blue-900; */
  @apply font-heading font-medium text-24 text-blue-900;
}

.title-s {
  @apply font-heading font-medium text-20 text-blue-900;
}

details summary::-webkit-details-marker {
  display: none;
}
