.tippy-box {
  @apply bg-black p-16 text-14 text-grey rounded-8;
}

.tippy-content {
  @apply flex flex-col gap-8 p-0;
}

.tippy-arrow {
  @apply text-black;
}
