.drag-drop-area {
  @apply py-64 px-40 relative text-center rounded-16;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A69A8CFF' stroke-width='1' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &__instruction {
    @apply mb-20 text-15 text-blue-800;
  }

  &__button {
    @apply rounded-full bg-black py-16 px-32 inline-flex gap-8 items-center font-normal text-grey;
  }
  &__button-icon {
    @apply size-24 pointer-events-none rotate-180;
  }
}
