/* Hide the select that gets replaced with select2 */
select.select2-hidden-accessible {
  @apply hidden;
}

select[multiple] {
  @apply max-h-[42px];
}

/* Style the select2 */
.select2 {
  @apply block w-full;

  & > .selection {
    @apply w-full block;
  }

  .select2-selection {
    @apply w-full block appearance-none border border-white outline-offset-2 bg-white text-14 leading-[1.7] rounded-8 py-8 px-12 relative pr-36 cursor-pointer hover:bg-blue-200 hover:border-blue-300 basic-animation;

    &:focus-visible {
      outline: 2px solid #000;
    }
  }
}

.select2-selection__rendered {
  @apply block text-ellipsis overflow-hidden whitespace-nowrap;
}

.select2.select2-container {
  @apply relative;
  .select2-selection__arrow {
    @apply absolute w-24 h-24 pointer-events-none z-10 basic-animation;
    top: 7px;
    right: 8px;

    b[role='presentation'] {
      @apply block w-full h-full basic-animation;
      background: url('../images/nav-arrow-down.svg') no-repeat center center;
      background-size: 22px 22px;
    }
  }
  &--open {
    .select2-selection {
      @apply border-beige-400 hover:bg-white;
    }
    .select2-selection__arrow b[role='presentation'] {
      rotate: 180deg;
    }

    .select2-selection--multiple {
      &:not(:has(.select2-selection__choice)) {
        &::after {
          @apply rotate-180;
        }

        .select2-search__field:placeholder-shown {
          @apply bg-white;
        }
      }
    }
  }
  &--focus {
    .select2-selection--multiple {
      &:not(:has(.select2-selection__choice)) {
        &::after {
          @apply rotate-180;
        }
      }
    }
  }
}

.select2-dropdown {
  @apply w-full block bg-white rounded-8 p-16 border border-beige-400 text-14 z-[1000] relative overflow-auto max-h-[375px];
}

.select2-search__field {
  @apply hidden border border-beige-700 rounded-8 p-8 pr-40 w-full;

  background-image: url('./images/icon-search.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5rem) center;
  background-size: 1.5rem;
}

.select2-search--inline {
  display: contents; /*this will make the container disappear, making the child the one who sets the width of the element*/
}

.select2-search__field:placeholder-shown {
  width: 100% !important; /*makes the placeholder to be 100% of the width while there are no options selected*/
  background-image: none;
}

.select2-selection--multiple {
  @apply relative;

  &:not(:has(.select2-selection__choice)) {
    &::after {
      @apply absolute right-8 top-[7px] size-24 basic-animation delay-100;
      background-image: url('../images/nav-arrow-down.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      content: '';
    }

    .select2-search__field:placeholder-shown {
      @apply inline-block text-transparent leading-[1.7] cursor-pointer focus:outline-0 py-0 pr-0 pl-2 border-none align-top h-23 text-ellipsis overflow-hidden placeholder:text-blue-900 basic-animation;
      resize: none;
    }

    &:hover {
      .select2-search__field:placeholder-shown {
        @apply bg-blue-200;
      }
    }

    .select2-count {
      @apply flex items-center gap-8 pl-2;
      &__counter {
        @apply bg-yellow-300 rounded-full size-24 inline-block text-center font-semibold;
      }
    }
  }
  &:has(.select2-selection__choice) {
    @apply pr-12;
  }

  .select2-selection__rendered {
    @apply flex flex-wrap gap-8;
  }

  .select2-selection__choice {
    @apply flex items-center rounded-8 pr-8;
    box-shadow: 0 0 0 1px theme('colors.beige.700') inset;

    &__display {
      @apply align-middle;
    }
  }

  .select2-selection__choice__remove {
    @apply rounded-8 size-22 content-center indent-[-9999px] align-middle shrink-0;
    background-image: url('../images/icon-close.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.875rem;
  }

  &:has(.select2-count) {
    .select2-search {
      @apply hidden;
    }
  }
}

.select2-has-search {
  .select2-search {
    @apply block pb-8 border-b border-beige-700 mb-8;
  }
  .select2-search__field {
    @apply block;
  }
}

.select2-results__options {
  @apply flex flex-col gap-8;
}

.select2-results__option {
  @apply p-8 w-full rounded-8 border border-beige-400 basic-animation;
  &--selectable {
    @apply cursor-pointer;
  }

  &--highlighted {
    @apply bg-blue-200 border-blue-300;
  }

  &--selected {
    @apply bg-yellow-300 border-yellow-500;
  }
}

.select-element-wrap {
  &--small {
    .select2 .select2-selection {
      @apply pl-12 pr-4 py-3 rounded-4 flex flex-row gap-4 items-center;
    }

    .select2.select2-container .select2-selection__arrow {
      @apply static shrink-0 ml-auto;
      b[role='presentation'] {
        background-image: url('../images/caret-down.svg');
      }
    }
  }

  &--gradient {
    .select2-selection {
      @apply bg-beige-200 border-beige-500 text-black font-semibold from-[#F2EBE3] bg-gradient-to-t hover:from-blue-300;
    }

    select:has(option[selected]) + .select2 {
      .select2-selection {
        @apply from-yellow-300;
      }
      &.select2-container--open .select2-selection {
        @apply from-white border-beige-400;
      }
    }

    .select2.select2-container--open {
      .select2-selection {
        @apply from-white bg-white;
      }
    }
  }

  &--title {
    .select2 .select2-selection,
    .select2.select2-container--open .select2-selection,
    select:has(option[selected]) + .select2 .select2-selection {
      @apply border-none bg-transparent text-24 font-heading py-0 pl-0 pr-52;
    }

    .select2-selection__arrow {
      @apply rounded-8 border border-beige-700;
    }

    &:hover {
      .select2-selection__arrow {
        @apply bg-blue-300;
      }
    }

    .select2-container--open {
      .select2-selection__arrow {
        @apply bg-beige-200;
      }
    }
  }

  &.active {
    .select2-selection__arrow {
      @apply invert brightness-0;
    }

    .select2-selection {
      @apply bg-black border-black text-white from-black;

      &--multiple {
        .select2-count__counter {
          @apply text-blue-900;
        }

        &:not(:has(.select2-selection__choice)) {
          &::after {
            @apply invert brightness-0;
          }
        }

        .select2-search__field:placeholder-shown {
          @apply bg-black placeholder:text-white;
        }
      }

      &:hover {
        @apply bg-black;
      }
    }

    .select2.select2-container--open {
      .select2-selection {
        @apply from-black;
      }
    }
  }
}

select:has(option[selected]) + .select2 {
  .select2-selection {
    @apply bg-yellow-300 border-yellow-500;
  }
}
.select-element-wrap {
  &.active {
    select:has(option[selected]) + .select2 {
      .select2-selection {
        @apply bg-black border-black from-black;
      }
    }
  }

  &--gradient {
    &.active {
      select:has(option[selected]) + .select2 {
        .select2-selection {
          @apply from-black;
        }
      }
    }
  }
}

/* Select, that is inside digila-form__label element */
.digila-form__label {
  .select2-selection {
    @apply bg-transparent p-0 border-none text-base hover:bg-transparent;

    &:focus-visible {
      outline: none;
    }
  }

  .select2.select2-container {
    &--open .select2-selection:hover {
      @apply bg-transparent;
    }
    .select2-selection__arrow {
      @apply hidden;
    }
  }
}
