.body-text {
  @apply text-17 text-blue-900 font-normal;

  p:not(:last-child) {
    @apply mb-20;
  }

  h3 {
    @apply font-semibold;

    &:not(:last-child) {
      @apply mb-12;
    }
  }

  a:not([class]) {
    @apply underline text-blue-900 font-bold basic-animation hover:text-blue-700;
  }

  &--sm {
    @apply text-15;

    p {
      @apply text-15;
    }

    h3 {
      @apply text-17;
    }
  }

  &--article {
    @apply text-black;

    p.excerpt,
    .excerpt {
      @apply font-semibold;
    }

    p,
    h2,
    h3,
    h4 {
      @apply text-black;
    }

    p:not(:last-child) {
      @apply mb-24 sm:mb-32 md:mb-40;
    }

    img {
      @apply my-24 sm:my-32 md:my-40 block rounded-8 overflow-hidden -ml-16 max-w-none w-[calc(100%+32px)] sm:w-full sm:ml-0 md:w-[calc(100%+128px)] md:-ml-64;
    }

    h2 {
      @apply font-heading font-medium text-24 text-black mb-8;
    }

    h3 {
      @apply font-heading font-medium text-20 text-black mb-8;
    }

    h4 {
      @apply font-heading font-medium text-18 text-black mb-8;
    }

    ul,
    ol {
      @apply my-24 sm:my-32 md:my-40;
      padding-left: 26px;

      > li:not(:last-child) {
        @apply mb-5;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;

      > li {
        @apply pl-4;
      }
    }
  }

  &--videos {
    @apply text-15;

    ul {
      @apply sm:columns-2 sm:gap-40;

      > li {
        @apply py-8 pl-32 relative break-inside-avoid-column;

        &:before {
          @apply absolute top-6 left-0 w-24 h-24;
          content: '';
          background: url('../images/icon-checked-yellow.svg') no-repeat center
            center;
          background-size: 24px 24px;
        }
      }
    }
  }

  &--terms {
    a:not([class]) {
      @apply font-normal underline text-current hover:text-blue-900;
    }

    ol {
      @apply text-20 text-blue-900 flex flex-col gap-64;
      list-style-type: decimal;
      counter-reset: item;

      li {
        @apply block;
        &::before {
          @apply float-left mr-4;
          content: counters(item, '.') '. ';
          counter-increment: item;
        }
      }

      ol {
        @apply text-blue-700 text-17 mt-40 gap-40;
      }
    }
  }
}
