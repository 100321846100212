.select-element-wrap {
  @apply relative cursor-pointer;

  /* &::after {
    @apply absolute w-24 h-24 pointer-events-none;
    content: '';
    top: calc(50% - 1px);
    transform: translateY(-50%);
    right: 8px;
    background: url('../images/nav-arrow-down.svg') no-repeat center center;
    background-size: 22px 22px;
  } */
}

.select-element {
  @apply appearance-none bg-white text-14 rounded-8 py-8 px-12 relative pr-36 cursor-pointer w-full xs:w-auto;
}

.select-element-wrap.active {
  &:after {
    @apply invert brightness-0;
  }

  .select-element {
    @apply bg-black text-white;
  }
}

.search-box {
  @apply appearance-none bg-white text-14 rounded-8 py-8 px-12 relative pr-36 w-full xs:min-w-[180px] xs:focus:min-w-[280px] text-ellipsis basic-animation;
}

.assessment-select {
  @apply transition-colors duration-300 hover:bg-white border border-beige-700 flex items-center justify-center pl-32 pr-16 py-16 font-semibold text-black rounded-full bg-beige-100 h-52 text-15 appearance-none cursor-pointer;
}
