.video-modal {
  @apply w-full h-full bg-gradient;
  z-index: 1001;

  &__content {
    @apply relative overflow-hidden m-16;
  }

  &::backdrop {
    @apply bg-gradient;
  }
}
