.stacked-thumbnails {
  @apply grid grid-rows-1;
}

.stacked-thumbnails__item {
  @apply row-start-1 relative;

  & + .stacked-thumbnails__item {
    @apply opacity-50 -ml-64;
  }

  &:nth-child(1) {
    @apply z-10;
  }

  &:nth-child(2) {
    @apply z-[9];
  }

  &:nth-child(3) {
    @apply z-[8];
  }
}
