.comments-list {
  &__header {
    @apply bg-yellow-200 p-16 flex flex-col gap-16;
  }

  &__description {
    @apply text-black text-15;
  }

  &--light {
    .comments-list__header {
      @apply bg-beige-200;
    }
  }
}
