.setting-card {
  @apply p-16 text-blue-800 bg-white/30 xs:p-24 rounded-24 md:p-40 @container;

  &__header {
    @apply flex flex-row gap-x-8 @[350px]:gap-x-40 mb-16;
  }

  &__title {
    @apply font-semibold leading-normal text-blue-900 text-20 font-heading;
  }

  &__content {
    @apply flex flex-col gap-32;
  }

  &__item {
    @apply flex flex-col gap-y-8 gap-x-16;
  }

  &__label {
    @apply text-blue-700 text-15 flex gap-x-16 w-full;
  }

  &__value {
    @apply text-blue-900 font-semibold;

    &--placeholder {
      @apply text-beige-700 font-normal;
    }
  }

  &__link {
    @apply text-blue-700 font-bold underline text-15;
  }

  &__footnote {
    @apply text-blue-700 text-15 mt-8;
  }

  &__separator {
    @apply border border-beige-700 w-80;
  }

  &__description {
    @apply text-15;
  }
}
