.assessment-trigger {
  @apply transition-colors duration-300 hover:bg-white -outline-offset-4 border border-beige-700 flex items-center justify-center pl-32 pr-16 py-16 font-semibold text-black rounded-full bg-beige-100 h-52 text-15;

  &.active {
    @apply relative bg-white;

    /* &:after {
      @apply absolute left-0 w-full rounded-full border border-beige-700 border-r-transparent rounded-r-none z-10;
      content: '';
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
    }

    &:hover {
      &:after {
        @apply z-0;
      }
    } */
  }

  &.assessment-trigger--locked {
    @apply bg-beige-700;
  }
}

.assessment-items {
  @apply w-0 transform overflow-hidden basic-animation relative font-semibold whitespace-nowrap text-15 h-52 rounded-r-full bg-beige-100 rounded-24 border-transparent border-beige-700 border;
  margin-left: -38px;

  [data-active-toggle] {
    @apply opacity-0 w-0 overflow-hidden p-0 m-0;

    &.is-active {
      @apply opacity-100 w-auto pl-8 pr-32 ml-8;
    }
  }

  &.is-visible {
    @apply border-l-transparent w-auto;
    transform: translateX(0);

    [data-active-toggle] {
      @apply opacity-100 w-auto pl-8 pr-32 ml-8;
    }
  }

  &:has([data-active-toggle].is-active) {
    @apply w-auto;
  }
}

@starting-style {
  .assessment-items {
    @apply w-0;

    [data-active-toggle] {
      @apply w-0 opacity-0 p-0 m-0;
    }
  }
}

[data-toggle-element] {
  [data-toggle-element-icon] {
    @apply transition-transform duration-300;
  }

  &.active {
    [data-toggle-element-icon] {
      @apply transform rotate-180;
    }
  }
}

.assessment-locked-selection {
  @apply transition-colors w-full xs:w-auto duration-300 border-beige-700 flex items-center justify-center pl-32 pr-16 py-16 font-semibold text-black rounded-full bg-beige-700 h-52 text-15 appearance-none cursor-pointer;
}

.assessment-locked-content {
  @apply hidden;

  &.digila-dropdown-open {
    @apply block;
  }
}
