@keyframes sun0_t {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes sun1_d {
  0% {
    d: path('M32,20L35,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  16.667% {
    d: path('M32,20L33,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  33.333% {
    d: path('M32,20L35,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path('M32,20L33,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  66.667% {
    d: path('M32,20L35,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  83.333% {
    d: path('M32,20L33,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path('M32,20L35,20');
  }
}
@keyframes sun2_d {
  0% {
    d: path('M20,8L20,4');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  16.667% {
    d: path('M20,8L20,7');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  33.333% {
    d: path('M20,8L20,4');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path('M20,8L20,7');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  66.667% {
    d: path('M20,8L20,4');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  83.333% {
    d: path('M20,8L20,7');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path('M20,8L20,4');
  }
}
@keyframes sun3_d {
  0% {
    d: path('M20,35L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  16.667% {
    d: path('M20,32L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  33.333% {
    d: path('M20,35L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path('M20,32L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  66.667% {
    d: path('M20,35L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  83.333% {
    d: path('M20,32L20,31');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path('M20,35L20,31');
  }
}
@keyframes sun4_d {
  0% {
    d: path('M5,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  16.667% {
    d: path('M7,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  33.333% {
    d: path('M5,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path('M7,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  66.667% {
    d: path('M5,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  83.333% {
    d: path('M7,20L8,20');
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path('M5,20L8,20');
  }
}
