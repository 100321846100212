.progress-bar {
  @apply relative;

  --progress-duration: 1000ms;
}

.progress-bar__background {
  @apply w-full h-8 bg-white rounded-full;
}

.progress-bar__indicator {
  @apply absolute h-full rounded-full top-0 left-0;

  &--main {
    width: 0;
    animation: calc(var(--progress-duration) * var(--percentage-main) / 100)
      ease-in progress-bar-main-animation;
    animation-fill-mode: forwards;
  }

  &--target {
    @apply rounded-l-none;
    width: 0;
    left: calc(calc(1% * var(--percentage-main)) - 3px);
    animation: calc(
        var(--progress-duration) -
          calc(var(--progress-duration) * var(--percentage-main) / 100)
      )
      ease-out calc(var(--progress-duration) * var(--percentage-main) / 100)
      progress-bar-target-animation;
    animation-fill-mode: forwards;
  }
}

.progress-bar--only-main {
  .progress-bar__indicator--main {
    animation-timing-function: ease-in-out;
    animation-duration: var(--progress-duration);
  }
}

/* If in swiper, activate animation on active state */
.swiper-slide {
  .progress-bar__indicator {
    &--main {
      animation-name: initial;
    }

    &--target {
      animation-name: initial;
    }
  }

  &.swiper-slide-active {
    .progress-bar__indicator {
      &--main {
        animation-name: progress-bar-main-animation;
      }

      &--target {
        animation-name: progress-bar-target-animation;
      }
    }
  }
}

@keyframes progress-bar-target-animation {
  1% {
    @apply w-0;
  }
  100% {
    width: calc(
      calc(1% * calc(var(--percentage-target) - var(--percentage-main))) + 3px
    );
  }
}

@keyframes progress-bar-main-animation {
  1% {
    @apply w-0;
  }
  100% {
    width: calc(1% * var(--percentage-main));
  }
}
