@keyframes chat0_d {
  0% {
    d: path(
      'M1,20C1,9.5,9.5,1,20,1C30.5,1,39,9.5,39,20C39,30.5,30.5,39,20,39C16.7,39,13.6,38.1,11,36.7L3.5,38.1C3.1,38.1,2.6,38,2.3,37.7C2,37.4,1.9,36.9,1.9,36.5L3.3,29C1.9,26.4,1,23.3,1,20Z'
    );
    animation-timing-function: cubic-bezier(0.2, 0.6, 0.6, 1);
  }
  26.433% {
    d: path(
      'M3.6,20C3.6,10.9,10.9,3.6,20,3.6C29.1,3.6,36.4,10.9,36.4,20C36.4,29.1,29.1,36.4,20,36.4C17.2,36.4,14.5,35.7,12.2,34.4L5.7,35.6C5.3,35.7,5,35.6,4.7,35.3C4.4,35,4.3,34.7,4.4,34.3L5.6,27.8C4.3,25.5,3.6,22.8,3.6,20Z'
    );
  }
  76.3% {
    d: path(
      'M3.6,20C3.6,10.9,10.9,3.6,20,3.6C29.1,3.6,36.4,10.9,36.4,20C36.4,29.1,29.1,36.4,20,36.4C17.2,36.4,14.5,35.7,12.2,34.4L5.7,35.6C5.3,35.7,5,35.6,4.7,35.3C4.4,35,4.3,34.7,4.4,34.3L5.6,27.8C4.3,25.5,3.6,22.8,3.6,20Z'
    );
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path(
      'M1,20C1,9.5,9.5,1,20,1C30.5,1,39,9.5,39,20C39,30.5,30.5,39,20,39C16.7,39,13.6,38.1,11,36.7L3.5,38.1C3.1,38.1,2.6,38,2.3,37.7C2,37.4,1.9,36.9,1.9,36.5L3.3,29C1.9,26.4,1,23.3,1,20Z'
    );
  }
}
@keyframes chat1_t {
  0% {
    transform: translate(10.6px, 24.1px);
  }
  2.567% {
    transform: translate(10.6px, 24.1px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  26.4% {
    transform: translate(10.6px, 21.2px);
  }
  79.067% {
    transform: translate(10.6px, 21.2px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  99.967% {
    transform: translate(10.6px, 17.5px);
  }
  100% {
    transform: translate(10.6px, 17.5px);
  }
}
@keyframes chat1_o {
  0% {
    opacity: 0;
  }
  2.567% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  26.467% {
    opacity: 1;
  }
  79.067% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  99.967% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes chat2_t {
  0% {
    transform: translate(10.6px, 17px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  23.9% {
    transform: translate(10.6px, 14.1px);
  }
  76.5% {
    transform: translate(10.6px, 14.1px);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  97.4% {
    transform: translate(10.6px, 10.5px);
  }
  100% {
    transform: translate(10.6px, 10.5px);
  }
}
@keyframes chat2_o {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  23.9% {
    opacity: 1;
  }
  76.5% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  97.4% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
