.compact-container {
  container-type: inline-size;
  container-name: compact-container;
  overflow: hidden;
}

.swiper-initialized .compact-container__hide-small,
.swiper-initialized .compact-container__hide-small-var,
.swiper-initialized .compact-container__hide-tiny {
  @apply transition-all;
}

@container compact-container (max-width: 300px) {
  .compact-container__hide-small {
    @apply opacity-0 h-0;
  }
}

@container compact-container (max-width: 170px) {
  .compact-container__hide-small-var {
    @apply opacity-0 h-0;
  }
}

@container compact-container (max-width: 120px) {
  .compact-container__hide-tiny {
    @apply opacity-0 h-0;
  }
}
