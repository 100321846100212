.curriculum-stages {
  @apply text-blue-700 text-15 font-semibold overflow-auto relative px-16 -ml-16 w-[100vw] sm:w-full;

  &::before {
    @apply bg-blue-700 h-px absolute bottom-14 left-0 w-16 xs:hidden;
    content: '';
  }
}

.curriculum-stages__content {
  @apply flex;
}

.curriculum-stages__stage {
  @apply relative pb-40 w-136 xs:w-104 md:w-116 shrink-0;

  &::before {
    @apply bg-blue-600 h-px absolute bottom-14 left-24 right-0;
    content: '';
  }

  &--current {
    &::before {
      @apply bg-blue-700 left-48;
    }

    .curriculum-stages__bullet {
      @apply w-32 h-32 bg-transparent rounded-none left-8 bottom-0;
      background-image: url('images/sun.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2rem;

      &::before {
        @apply hidden;
      }
    }
  }

  &--active {
    @apply text-black font-bold;

    &::before {
      @apply bg-blue-700;
    }

    .curriculum-stages__bullet {
      &::before {
        @apply bg-yellow-500 border;
      }

      &::after {
        @apply border-yellow-500;
      }
    }

    &.curriculum-stages__stage--current {
      .curriculum-stages__bullet::before,
      .curriculum-stages__bullet::after {
        @apply hidden;
      }
    }

    .curriculum-stages__label {
      translate: 0 -0.25rem;
    }
  }

  &--passed {
    &::before {
      @apply bg-blue-700;
    }
    .curriculum-stages__bullet {
      &::before {
        @apply bg-blue-800;
      }
    }
  }
}

.curriculum-stages__link {
  @apply block -outline-offset-2;

  &:hover {
    .curriculum-stages__label {
      @apply text-blue-800;
    }
    .curriculum-stages__stage {
      .curriculum-stages__bullet {
        /* @apply bg-white border-4 outline-2 outline-white outline border-blue-600 ring-[3px] ring-blue-600 w-20 h-20 -translate-x-2 translate-y-2; */
        &::before {
          @apply bg-transparent border-blue-600;
        }

        &::after {
          @apply border-blue-600;
        }
      }

      &.curriculum-stages__stage--current {
        .curriculum-stages__bullet::before,
        .curriculum-stages__bullet::after {
          @apply hidden;
        }
      }

      &.curriculum-stages__stage--active {
        .curriculum-stages__label {
          @apply text-black;
        }

        .curriculum-stages__bullet {
          &::before {
            @apply bg-yellow-500 border-black;
          }

          &::after {
            @apply border-yellow-500;
          }
        }
      }

      &.curriculum-stages__stage--passed {
        .curriculum-stages__bullet {
          &::before {
            @apply bg-blue-800 border-transparent;
          }

          &::after {
            @apply border-blue-600;
          }
        }
      }
    }
  }
}

.curriculum-stages__bullet {
  @apply w-24 h-24 absolute bottom-3 left-0 rounded-full overflow-hidden;

  &::before {
    @apply w-16 h-16 absolute left-1/2 top-1/2 border-4 border-transparent bg-blue-300 rounded-full -translate-x-1/2 -translate-y-1/2 basic-animation;
    content: '';
  }
  &::after {
    @apply w-24 h-24 absolute border-2 border-transparent left-1/2 top-1/2 rounded-full -translate-x-1/2 -translate-y-1/2 basic-animation;
    content: '';
  }
}
