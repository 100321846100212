.comment {
  &__header {
    @apply flex flex-wrap items-center gap-8 text-13 text-blue-900 font-medium mb-8;
  }
  &__content {
    @apply text-black text-15 font-semibold;

    & > * {
      @apply mb-8;

      &:last-child {
        @apply mb-0;
      }
    }
  }
}
