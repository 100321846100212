.child-card-compact {
  @apply flex items-center w-full gap-16 text-base @container;

  &__image-cont {
    @apply relative flex-shrink-0 rounded-full size-40 ring-2 ring-beige-700;
  }

  &__image {
    @apply absolute object-cover w-full h-full rounded-full border-2 border-white overflow-hidden shadow-profile;
  }

  &__title {
    @apply font-bold text-blue-800 @[285px]:text-base text-15;
  }

  &--large {
    .child-card-compact__image-cont {
      @apply size-64 ring-white border-transparent border-2;
    }

    .child-card-compact__title {
      @apply font-semibold text-blue-900 text-20 font-heading;
    }
  }
}
