.tabs {
  &__nav {
    @apply w-full overflow-hidden;

    ul {
      @apply flex overflow-auto;
    }

    li {
      @apply whitespace-nowrap;

      a {
        @apply whitespace-nowrap -outline-offset-2;
      }
    }
  }
}

[role='tabpanel'] {
  @apply px-16 sm:px-24 py-16;
}

[role='tab'] {
  @apply bg-beige-100 mr-4;
}

[role='tabpanel'][aria-hidden='true'] {
  display: none;
}

[aria-selected='true'] {
  @apply bg-white;
}

.tabs--rounded {
  .tabs__nav {
    @apply text-center overflow-visible;

    ul {
      @apply inline-flex bg-beige-100 w-auto border border-beige-700 rounded-full overflow-visible mb-24 md:mb-40;

      li {
        a {
          @apply py-16 px-32 block rounded-full border border-transparent -m-px bg-transparent;

          &[aria-selected='true'] {
            @apply border-beige-700 bg-white;
          }
        }
      }
    }
  }

  [role='tabpanel'] {
    @apply p-0;
  }
}
