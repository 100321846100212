@layer components {
  .gutter {
    @apply px-16 sm:px-40 xl:px-80;
  }

  .gutter-large {
    @apply px-16 sm:px-40 xl:px-160;
  }

  .gutter-article-text {
    @apply px-32 sm:px-64 xl:px-80;
  }

  .gutter-text {
    @apply px-32 xl:px-80;
  }

  .gutter-dashboard,
  .gutter-footer {
    @apply px-16 md:px-64;
  }

  .basic-animation {
    @apply transition-all duration-200 ease-in-out;
  }

  .content {
    @apply mx-auto w-full;
    max-width: 1440px;
  }

  .content-md {
    @apply mx-auto w-full;
    max-width: 1120px;
  }

  .content-sm-var {
    @apply mx-auto w-full;
    max-width: 974px;
  }

  .content-sm {
    @apply mx-auto w-full;
    max-width: 846px;
  }

  .content-xs {
    @apply mx-auto w-full;
    max-width: 437px;
  }

  .bg-gradient {
    background: linear-gradient(180deg, #fff5ea 0%, #f2ebe3 100%);
  }

  .aspect-2-1 {
    aspect-ratio: 2 / 1;
  }

  .aspect-square {
    aspect-ratio: 1 / 1;
  }
}

.logo {
  width: 94px;
  height: 24px;
}

.logo-footer {
  width: 189px;
  height: 48px;
}

.front-video-wrap {
  aspect-ratio: 10 / 16;
  box-shadow: 37.114px 160.772px 42px 0px rgba(37, 26, 0, 0.07),
    18.789px 81.391px 18.309px 0px rgba(37, 26, 0, 0.05),
    7.423px 32.154px 6.825px 0px rgba(37, 26, 0, 0.04),
    1.624px 7.034px 2.428px 0px rgba(37, 26, 0, 0.02);

  @screen 2xs {
    aspect-ratio: 12 / 16;
  }

  @screen xs {
    @apply h-full;
    aspect-ratio: unset;
  }

  @screen md {
    @apply h-auto;
    aspect-ratio: 16 / 12.5;
  }

  @screen lg {
    aspect-ratio: 16 / 10.5;
  }
}

.front-video-wrap .play-pause-btn {
  @apply z-20 absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-pause-btn {
  .icon-pause {
    @apply hidden;
  }

  &.playing {
    .icon-play {
      @apply hidden;
    }

    .icon-pause {
      @apply block;
    }
  }
}

.mute-unmute-btn {
  .icon-unmute {
    @apply hidden;
  }
  &.muted {
    .icon-mute {
      @apply hidden;
    }

    .icon-unmute {
      @apply block;
    }
  }
}

.video-wrap.playing {
  .play-pause-btn,
  .mute-unmute-btn,
  .full-screen-btn {
    @apply opacity-0 transition-opacity duration-500 ease-in-out;
  }

  &:hover {
    .play-pause-btn,
    .mute-unmute-btn,
    .full-screen-btn {
      @apply opacity-100;
    }
  }
}

.aspect-frame {
  aspect-ratio: 16 / 13;
}

.aspect-vertical-video {
  aspect-ratio: 9 / 16;
}

.aspect-person {
  aspect-ratio: 11 / 16;

  @screen md {
    aspect-ratio: 13 / 16;
  }
}

.aspect-interests {
  aspect-ratio: 80 / 41;

  @screen xs {
    aspect-ratio: 31 / 20;
  }

  @screen md {
    aspect-ratio: 215 / 120;
  }
}

.aspect-investor {
  aspect-ratio: 280 / 380;

  @screen md {
    aspect-ratio: 540 / 558;
  }
}

.tag {
  @apply inline-block px-8 py-4 rounded-full text-blue-900 font-bold text-12 tracking-wider;

  &--curriculum {
    @apply text-15 font-medium text-blue-800 rounded-8 py-0 leading-[1.6];
  }
}

.price-tag {
  @apply rounded-full bg-blue-200 px-24 py-8 text-24 font-heading font-medium text-blue-800 inline-block;
}

.badge {
  @apply inline-block px-8 py-4 rounded-4 text-black bg-yellow-300 font-semibold text-14 uppercase tracking-wider;
}

.info-btn {
  .icon-close {
    @apply hidden;
  }

  &.active {
    .icon-info {
      @apply hidden;
    }

    .icon-close {
      @apply block;
    }
  }
}

[data-toggle='dropdown'],
[data-toggle='menu'] {
  .icon-close {
    @apply hidden pointer-events-none;
  }

  .icon-initial {
    @apply pointer-events-none;
  }

  &[aria-expanded='true'] {
    .icon-close {
      @apply block;
    }

    .icon-initial {
      @apply hidden;
    }
  }
}

.info-window {
  @apply opacity-0 pointer-events-none basic-animation;

  &.is-visible {
    @apply opacity-100 pointer-events-auto;
  }
}

.person-card-content {
  @apply basic-animation cursor-pointer;

  &-extra {
    @apply hidden;
  }

  &-icon {
    @apply basic-animation;
  }

  &.is-open {
    @apply h-full;

    .person-card-content-extra {
      @apply block;
    }

    .person-card-content-icon {
      @apply transform rotate-180;
    }
  }
}

body.fixed-nav-open {
  overflow: hidden;
}

.horizontal-phone-frame {
  @apply relative;

  &:after {
    @apply absolute top-0 left-0 w-full h-full bg-transparent -mt-10;
    border-radius: 50px;
    content: '';
    box-shadow: 37.114px 160.772px 42px 0px rgba(37, 26, 0, 0.07),
      18.789px 81.391px 18.309px 0px rgba(37, 26, 0, 0.05),
      7.423px 32.154px 6.825px 0px rgba(37, 26, 0, 0.04),
      1.624px 7.034px 2.428px 0px rgba(37, 26, 0, 0.02);
  }

  &-video-wrap {
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
  }
}

.language-selection {
  @apply appearance-none pl-42 py-16 pr-16 cursor-pointer;
  background-image: url('images/caret-right.svg');
  background-repeat: no-repeat;
  background-position: 0.75rem center;
  background-size: 1.5rem;
}

.separator {
  @apply size-8 rounded-full bg-yellow-500 inline-block align-top shrink-0;

  &--md {
    @apply size-6;
  }

  &--sm {
    @apply size-4;
  }

  &--current {
    @apply bg-current;
  }
}
