.digila-form {
  @apply flex flex-col gap-24;

  &--recovery {
    @apply gap-40 sm:gap-48;
  }
}

.digila-form__label {
  @apply bg-blue-100 rounded-t-4 py-8 px-12 border-b border-black w-full flex flex-col gap-4 text-12 cursor-pointer basic-animation hover:bg-yellow-200;
  color: #49454f;

  &:has(.select2-selection:focus-visible),
  &:has(input:focus-visible) {
    @apply bg-yellow-200;
    outline: 2px solid #000;
    outline-offset: 0;
  }

  &:has(input[disabled]),
  &:has(input[readonly]) {
    @apply bg-beige-400 border-beige-700;
  }

  &:has(input[disabled]) {
    @apply cursor-default;
  }

  &.error {
    @apply bg-white text-neg-200 border-neg-200;
  }

  /* If label contains select element */
  &:has(select) {
    @apply relative pr-52;

    &::after {
      @apply absolute right-20 top-1/2 -translate-y-1/2 size-24 pointer-events-none basic-animation;
      content: '';
      background: url('../images/nav-arrow-down.svg') no-repeat center center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:has(.select2.select2-container--open) {
      &::after {
        @apply rotate-180;
      }
    }
  }
}

.digila-form__input,
.digila-form__textarea {
  @apply bg-transparent block text-base text-black w-full;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    @apply text-blue-700 font-normal;
  }
}

.digila-form__checkbox-label {
  @apply flex items-center gap-8 text-15 text-blue-700 cursor-pointer pl-36 relative;

  input[type='checkbox'] {
    @apply absolute w-0 h-0 opacity-0;
  }

  &::before {
    @apply absolute left-0 -top-2 size-24 rounded-4 border border-blue-800 bg-blue-100 inline-block align-top basic-animation;
    content: '';
  }

  &::after {
    @apply absolute size-24 left-0 -top-2;
    content: '';
  }

  &:has(input:checked) {
    &::after {
      background-image: url('./images/icon-check.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      &::before {
        @apply bg-yellow-300;
      }
    }
  }

  &:has(input:focus) {
    &::before {
      @apply bg-yellow-200;
    }
  }

  &:has(input:focus-visible) {
    &::before {
      @apply ring-2 ring-[#000] ring-offset-2;
    }
  }
}

.digila-form__message {
  @apply text-neg-200 text-15 -mb-16;
}

.digila-form__text-button {
  @apply flex items-center pt-8 gap-8 font-bold text-blue-700 underline text-15 hover:no-underline;
}
