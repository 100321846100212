@keyframes rainbow0_d {
  0% {
    d: path(
      'M7.5,21.2L7.5,17.7C7.5,11,13,5.5,19.7,5.5C26.5,5.5,31.9,11,31.9,17.7L31.9,17.7'
    );
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path(
      'M7.5,17.7L7.5,17.7C7.5,11,13,5.5,19.7,5.5C26.5,5.5,31.9,11,31.9,17.7L31.9,21'
    );
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path(
      'M7.5,21.2L7.5,17.7C7.5,11,13,5.5,19.7,5.5C26.5,5.5,31.9,11,31.9,17.7L31.9,17.7'
    );
  }
}
@keyframes rainbow1_d {
  0% {
    d: path(
      'M11.1,20.2L11.1,18.4C11.1,13.6,14.9,9.7,19.7,9.7C24.5,9.7,28.1,13.4,28.3,18L28.3,18'
    );
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    d: path(
      'M11.1,18.4L11.1,18.4C11.1,13.6,14.9,9.7,19.7,9.7C24.5,9.7,28.1,13.4,28.3,18L28.3,20.2'
    );
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    d: path(
      'M11.1,20.2L11.1,18.4C11.1,13.6,14.9,9.7,19.7,9.7C24.5,9.7,28.1,13.4,28.3,18L28.3,18'
    );
  }
}
@keyframes rainbow2_t {
  0% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    translate: 0px 3px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 0px;
  }
}
@keyframes rainbow3_t {
  0% {
    translate: 0px 0px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  50% {
    translate: 0px -3px;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    translate: 0px 0px;
  }
}
